<template>
<v-container>
  <v-row style="height:50px; background-color:#f1f3f9;" class="align-center pl-4">
    <div class="" @click="goBack" style="cursor:pointer;">
      <v-icon>
        mdi-chevron-left
      </v-icon>
      <span class="text-subtitle-1">
        {{this.authUser.employeeName}}的 {{this.filter.task_start_date.replace(/(.*)-(.*)-(.*)/, "$1年$2月")}} {{this.filter.task_start_date.replace(/(.*)-(.*)-(.*)/, "$2月")}}计划表
      </span>
    </div>
  </v-row>

  <v-row>
    <v-spacer></v-spacer>
    <v-menu bottom>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="pr-4">
          <v-icon small>mdi-help-circle</v-icon>
          <span>图例说明</span>
        </div>
      </template>

      <v-list dense>
        <v-list-item v-for="item in ganttHint">
          <v-list-item-title>
            <div style="width:80px; height:25px; text-align:center;" :style="{backgroundColor:item.color}">
              {{ item.label }}
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-row>
  <v-row class="pa-3">
    <gantt-elastic :options="options" :tasks="allTasks" :dynamic-style="dynamicStyle">
      <gantt-header slot="header" :options="options"> </gantt-header>
    </gantt-elastic>
  </v-row>
</v-container>

</template>

<script>
import moment from 'moment';
import {
  mapGetters,
  mapActions
} from 'vuex';
import {
  constant
} from "@/constants/constant.js";
import GanttElastic from "gantt-elastic";
import GanttHeader from "gantt-elastic-header";
import dayjs from "dayjs";

export default {
  name: 'SettingPage',
  components: {
    GanttElastic,
    GanttHeader,
    TaskEdit: () => import("@/components/common/taskEdit/TaskEdit.vue"),
  },
  data() {

    return {
      milestones: [],
      ganttHint: [],
      dynamicStyle: {
        "task-list-header-label": {
          "font-weight": "bold",
        },
      },
      options: {
        taskMapping: {
          progress: "percent",
        },
        maxRows: 100,
        maxHeight: 650,
        title: {
          label: "甘特图",
          html: true,
        },
        row: {
          height: 10,
        },
        calendar: {
          hour: {
            display: true,
          },
        },
        chart: {
          progress: {
            bar: false,
          },
          expander: {
            display: true,
          },
        },
        taskList: {
          expander: {
            straight: true,
          },
          columns: [{
              id: 2,
              label: "描述",
              value: "label",
              width: 250,
              expander: true,
              html: true,
              events: {
                click({
                  data,
                  column
                }) {
                  if (data.type == "task") {

                    gVm.methods.selectTask(data.taskObj);
                  }
                },
              },
            },
            {
              id: 3,
              label: "负责人",
              value: "owner",
              width: 80,
              html: true,
            },
            {
              id: 3,
              label: "日期",
              value: (task) =>
                task.start ?
                dayjs(task.start).format("YYYY-MM-DD") +
                "~" +
                dayjs(task.end).format("YYYY-MM-DD") : "",
              width: 150,
            },
            {
              id: 6,
              label: "完成度",
              // value: "task_progress_status",
              value: (task) =>
                task.task_progress_status == "0/0" ?
                "" : task.task_progress_status,
              width: 70,
              html: true,
            },
            {
              id: 7,
              label: "评分",
              value: "task_confidence",
              width: 70,
              html: true,
            },
          ],
        },
        locale: {
          code: "zh",
          Now: "今天​​",
          "X-Scale": "缩放日期",
          "Y-Scale": "缩放高度",
          "Task list width": "任务列表宽度",
          "Before/After": "扩张",
          "Display task list": "显示任务列表",
          months: "一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月".split(
            "_"
          ),
          weekdays: "星期日_星期一_星期二_星期三_星期四_星期五_星期六".split(
            "_"
          ),
        },
      },
      filter: {},
      headers: [{
          text: '序号',
          align: 'center',
          sortable: true,
          divider: true,
          value: 'num',
          width: '80'
        },
        {
          text: '计划项名称',
          divider: true,
          value: 'task_name',
        },
        {
          text: '备注',
          // divider: true,
          sortable: false,
          value: ''
        },
      ],
    }
  },
  created() {
    this.filter = JSON.parse(this.$route.query.filter);

    this.ganttHint = constant.ganttColor;
    this.updateData();

  },
  computed: {
    ...mapGetters('task', ['tasks']),
    ...mapGetters("auth", ["authUser"]),
		allTasks() {
			let taskArr = [];
			this.tasks.map(task=>{
				taskArr = [...taskArr, ...this.genTaskList(0, task)];
			});
			return taskArr;
		},
    newList() {
      return this.tasks.map((item, index) => {
        item.num = index + 1;
        return item;
      });
    },
  },
  methods: {
    ...mapActions('task', ['getTasks', 'addTask']),
    ...mapActions("user", ['getTaskUsers']),
    updateData() {
      this.getTasks(this.filter).then(res => {

      })
    },
		genTaskList(pId, task) {
      let arr = [];
      let itemStart = new Date(task.task_start_date);
      let itemEnd = new Date(task.task_end_date);
			let now = new Date();
      let alltask = 0,
        comtask = 0;

			let gColor = constant.ganttColor[0].color;
			if(itemEnd<now){
				gColor = constant.ganttColor[2].color;
			}
			if(task.task_status ==1){
				gColor = constant.ganttColor[1].color;
			}
			if(task.task_status ==3){
				gColor = constant.ganttColor[3].color;
			}
			if(task.task_status ==4){
				gColor = constant.ganttColor[5].color;
			}
			if(task.task_status ==5){
				gColor = constant.ganttColor[4].color;
			}

      task.task_tasks.map((e) => {
        alltask++;
        if (e.task_status == 1) {
          comtask++;
        }
        arr = [...this.genTaskList(task.task_id, e)];
      });

      let taskObj = {
        id: task.task_id,
        parentId: pId?pId:null,
        label: "<span style='cursor:pointer;'>" +
          task.task_name +
          "</span>",
        owner: this.$options.filters.username(task.task_owner),
        start: itemStart.getTime(),
        end: itemEnd.getTime(),
        duration: Math.max(
          itemEnd.getTime() - itemStart.getTime(),
          10 * 60 * 60 * 1000
        ),
        percent: task.task_progress,
        task_progress_status: comtask + "/" + alltask,
        task_confidence: "-",
        taskObj: task,
        type: "task",
        collapsed: false,
        style: {
          base: {
            fill: gColor,
            stroke: "#0077C0",
          },
        },
      };
      arr.push(taskObj);

      return arr;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
}
</script>

<style>
.pp_content .v-data-footer {
  display: none !important;
}
</style><style scoped>
.setting-container {
  padding: 10px 20px;
}

.palnprint .pp_tips .person,
.palnprint .pp_tips .firm {
  position: absolute;
  display: inline-block;
  margin-top: 5px;
}

.palnprint .pp_tips .person {
  left: 0;
}

.palnprint .pp_tips .firm {
  right: 0;
}

.palnprint {
  width: 555px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  font-size: 12px;
  font-family: Arial, "Microsoft YaHei", SimHei, SimSun;
}

.palnprint .pp_head {
  text-align: center;
  margin-top: 36px;
}

.palnprint .mark {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.palnprint .pp_content {
  margin-top: 24px;
  border: solid grey;
  border-width: 1px;
}

.palnprint .leader {
  height: 200px;
  position: relative;
  border: 1px solid #ddd;
  /* border-top: 0; */
}

.palnprint .leader .leader_title {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  background: #f7f7f7;
  height: 30px;
  line-height: 30px;
  width: 100%;
  text-indent: 10px;
  font-weight: 400;
  font-size: 12px;
}

.palnprint .leader .leader_date {
  display: inline-block;
  position: absolute;
  bottom: 10px;
  right: 10px;
  margin-right: 5px;
}

.palnprint .pp_tips {
  position: relative;
  margin-top: 30px;
  color: #aaa;
  font-size: 12px;
  border-top: 1px solid #ddd;
  margin-top: 30px;
  font-family: SimSun, "Microsoft YaHei", SimHei;
}

.palnprint .middle_table,
.palnprint .foot_table {
  width: 100%;
  border-collapse: collapse;
}
</style>
